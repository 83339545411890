import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcon, AdminIcons, AndroidIcon, IOsIcon } from "../Shared/AdminIcon";
import { CustomColumnContainer } from "../Shared/Formelements";
import { OsType } from "../Tools/ToolsScreen";
import { ScreenHeader } from "../UI/Elements";
import { ProductIdsType } from "./Subscriptions";
import { StringHelper } from "../../Handlers/Helpers";


type EmptySubscriptionType = {
    purchaseId: string,
    active: true,
    currentState: string,
    productId: ProductIdsType,
    timeStampMillis: number,
    createdTimeStampMillis: number,
}
export const SubscriptionsWithoutUser = () => {

    const dte = useDateHelper();
    const ui = useUI()

    useEffect(() => {
        ui.load();
    }, [])


    return (
        <div className="screen-subscriptions">
            <ScreenHeader text="Prenumerationer utan användare" icon={AdminIcons.dollarsign} />
            <div>
                <hr />
                <table className="tbl">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Store</th>
                            <th>Köpt</th>
                            <th>Köpt tid (UTC)</th>
                            <th>Produkt</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody style={{ opacity: ui.isLoading ? 0.5 : 1 }} >
                        {ui.data.map((sub, idx) => (
                            <TblLine
                                idx={idx}
                                key={sub?.purchaseId}
                                sub={sub}
                                onClick={() => {
                                    if (ui.selectedPurchaseId === sub?.purchaseId) {
                                        ui.setSelectedPurchaseId("");
                                    } else {
                                        ui.setSelectedPurchaseId(sub?.purchaseId);
                                    }
                                }}
                                open={ui.selectedPurchaseId === sub?.purchaseId}
                                saveUserOnSubs={async e => {
                                    const res = await ui.fixSubscriptionsWithoutUser(sub?.purchaseId, e);
                                    if (res?.updated) {
                                        ui.setSelectedPurchaseId("");
                                        ui.load();
                                    } else {
                                        alert(res?.message);
                                    }
                                    ui.removeUserData();
                                    return true;
                                }}
                                userData={ui.userData}
                                getUserData={ui.getUserData}
                                disabled={ui.isLoading}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TblLine = ({ idx, sub, onClick, open, saveUserOnSubs, userData, getUserData, disabled }: { idx: number, sub: EmptySubscriptionType, onClick(): void, open: boolean, saveUserOnSubs(e: string): Promise<boolean>, userData: UserDataType, getUserData(e: string): void, disabled: boolean }) => {
    const dte = useDateHelper();
    const [userId, setUserId] = useState<string>("");
    useEffect(() => {
        setUserId("");
    }, [, open]);

    const hasUserData = !!userData;

    return (
        <>
            <tr key={sub?.purchaseId} onClick={onClick} className="cursor-pointer">
                <td>{idx + 1}.</td>
                <td>{sub?.purchaseId?.length > 50 ? "Google" : "Apple"}</td>
                <td>{dte.timeAgo(sub?.createdTimeStampMillis / 1000)}</td>
                <td>{dte.epochToDateTime(sub?.createdTimeStampMillis / 1000)}</td>
                <td style={{ textDecoration: !sub?.active ? "line-through" : null }}>{sub?.productId}</td>
                <td>{sub?.currentState}</td>
            </tr>
            {open && (
                <>
                    <tr />
                    <tr>
                        <td colSpan={6}>
                            <div>
                                <div>
                                    <label>Koppla användare ({userData?.alias}): ({dte.epochToDateTime(sub?.createdTimeStampMillis / 1000)})&nbsp;</label>
                                </div>
                                <div>
                                    <input style={{ minWidth: "350px" }} type="text" placeholder="Ange id för användare" value={userId} onChange={e => setUserId(e.target.value)} disabled={disabled} />

                                    {hasUserData === false && (
                                        <button
                                            disabled={(StringHelper.isGuid(userId) !== true) || disabled}
                                            className="btn"
                                            onClick={async () => {
                                                const t = userId;
                                                getUserData(t)
                                            }}>Hämta användare</button>
                                    )}

                                    {hasUserData === true && (
                                        <button
                                            disabled={(StringHelper.isGuid(userId) !== true) || disabled}
                                            className="btn"
                                            onClick={async () => {
                                                if (window.confirm("Säker?")) {
                                                    return await saveUserOnSubs(userId)
                                                }
                                            }}>
                                            Koppla prenumeration till '{userData?.alias}'
                                        </button>
                                    )}
                                </div>

                            </div>
                        </td>
                    </tr>
                </>
            )}
        </>
    )
}


const useUI = () => {

    const [data, setdata] = useState<EmptySubscriptionType[]>([]);
    const [selectedPurchaseId, setSelectedPurchaseId] = useState<string>();
    const [userData, setUserData] = useState<UserDataType>();
    const [isLoading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        setUserData(undefined);
    }, [selectedPurchaseId])


    const load = async () => {
        const d = await FetchHandler.getJson("/api/subscriptions/nouser");
        setdata(d);
    }

    return {

        isLoading: isLoading, setIsLoading: setIsLoading,
        load: load,
        data: data,
        selectedPurchaseId: selectedPurchaseId,
        setSelectedPurchaseId: setSelectedPurchaseId,
        fixSubscriptionsWithoutUser: async (purchaseId: string, userId: string): Promise<FixSubscriptionsWithoutUserResponseType> => {
            setIsLoading(true);
            const p = {
                purchaseId: purchaseId,
                userId: userId
            };
            const d = await FetchHandler.patchJson("/api/subscriptions/nouser", p);
            setIsLoading(false);
            return d;
        },

        removeUserData: () => setUserData(undefined),
        userData: userData,
        getUserData: async (userId: string) => {
            setIsLoading(true);
            const d = await FetchHandler.getJson("/api/accounts?page=0&pagesize=100&sort=created&search=" + userId);
            setUserData(d?.[0]);
            setIsLoading(false);
        }
    }

}

type FixSubscriptionsWithoutUserResponseType = {
    updated: boolean,
    message: string
}
type UserDataType = {
    state: string
    totalNumberOfHits: number
    premiumSetBySubscription: boolean
    userCreatedUtc: string
    numberOfRoles: number
    id: string
    email: string
    emailConfirmed: boolean
    firstName: string
    lastName: string
    acceptsGdpr: any
    emailForConfirmation: string
    city: string
    premiumExpires: number
    trialExpires: number
    alias: string
}