import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useReduxState } from "../../hooks/reduxHook";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { useStatisticsMenu } from "./useStatisticsMenu";


type statsType = {
    period: number
    usersCreatedAccount: number
    usersTrialCreatedAccount: number
    numberOfSubscribed?: number
    usersConvertedFromLoggedInPercent?: number
    convertedToTrialPercent: number
    usersConvertedFromTrialPercent?: number
    numberOfSubscribedCurrent?: number
    usersConvertedFromLoggedInPercentCurrent?: number
    usersConvertedFromTrialPercentCurrent?: number
}


export const Statistics = () => {

    const menu = useStatisticsMenu();
    return (
        <div>
            <ScreenHeader text="Statistik" icon={AdminIcons.chart} />
            <ScreenMenu items={menu?.items} />
            <UserConversionStats />

        </div>
    )
}


const UserConversionStats = () => {

    const [data, setData] = useState<statsType[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    const loadStats = async () => {
        setLoading(true);
        let d: statsType[] = await FetchHandler.getJson("/api/stats/userconversion");
        d.sort((a, b) => {
            if (a.period > b.period) { return -1; }
            else if (a.period < b.period) { return 1; }
            else { return 0; }
        })
        setData(d);
        console.log(d);
        setLoading(false);

    }
    useEffect(() => {
        loadStats();
    }, []);

    useEffect(() => {
        setSumusersCreatedAccount(data?.map(m => m?.usersCreatedAccount).reduce((partialSum, a) => partialSum + a, 0));
        setSumusersTrialCreatedAccount(data?.map(m => m?.usersTrialCreatedAccount).reduce((partialSum, a) => partialSum + a, 0));
        setSumNumberOfSubscribed(data?.map(m => m?.numberOfSubscribed).reduce((partialSum, a) => partialSum + a, 0));
        setSumNumberOfSubscribing(data?.map(m => m?.numberOfSubscribedCurrent).reduce((partialSum, a) => partialSum + a, 0));
    }, [data]);

    const toPercent = (n: number) => {
        return n ? (Math.round(10 * n) / 10) + "%" : "";
    }

    const [sumUsersCreatedAccount, setSumusersCreatedAccount] = useState<number>(0)
    const [sumUsersTrialCreatedAccount, setSumusersTrialCreatedAccount] = useState<number>(0)
    const [sumNumberOfSubscribed, setSumNumberOfSubscribed] = useState<number>(0)
    const [sumNumberOfSubscribing, setSumNumberOfSubscribing] = useState<number>(0)

    return (

        <div>
            <h2>
                Konvertering av användare
            </h2>

            <LoadingBar loading={loading} />
            <table className="tbl">
                <thead>
                    <tr>
                        <th>
                            Period
                        </th>
                        <th>
                            Skapade anv
                        </th>
                        <th colSpan={2}>
                            Prenumerade
                        </th>
                        <th colSpan={2}>
                            Prenumererar
                        </th>                    </tr>
                </thead>
                <tbody>
                    {data && data?.map(s => (
                        <tr key={s.period}>
                            <td>
                                {s?.period}
                            </td>
                            <td>
                                {s?.usersCreatedAccount}
                            </td>

                            {/* numberOfSubscribed */}
                            <td>
                                {s?.numberOfSubscribed}
                            </td>
                            <td>
                                {toPercent(s?.usersConvertedFromLoggedInPercent)}
                            </td>

                            {/* numberOfSubscribedCurrent */}
                            <td>
                                {s?.numberOfSubscribedCurrent}
                            </td>
                            <td>
                                {toPercent(s?.usersConvertedFromLoggedInPercentCurrent)}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th></th>
                        <th>
                            {sumUsersCreatedAccount}
                        </th>
                        <th colSpan={2}>
                            {sumNumberOfSubscribed}
                        </th>
                        <th colSpan={2}>
                            {sumNumberOfSubscribing}
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )

}