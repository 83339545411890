import React, { useEffect, useState } from "react"
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader } from "../UI/Elements";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useUsers } from "../../hooks/useUsers";

export const Organizations = () => {


    const ui = useOrganizations();


    return (
        <div>
            <ScreenHeader text="Organisationer" icon={AdminIcons.house} />
            <div>
                <ThreeColumnContainer>
                    <div>
                        <label>Sök</label>
                        <input
                            placeholder="Sök..."
                            className="frm" type={"search"}
                            value={ui.search}
                            onChange={e => ui.setSearch(e.target.value)}
                            onKeyUp={e => {
                                if (e.key === "Enter") {
                                    ui.doSearch();
                                }
                            }}
                        />
                    </div>
                </ThreeColumnContainer>
            </div>
            <hr />
            <div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>OrgNr</th>
                            <th>Adress</th>
                            <th>Email</th>
                            <th>Karta</th>
                            <th>Ägare</th>
                            <th>Ägare (E-Post)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.data.map(n => (
                            <tr key={n?.id} data-id={n?.id}>
                                <td>{n?.name}</td>
                                <td>{n?.organizationnumber}</td>
                                <td>{n?.postaladdress}</td>
                                <td>{n?.billingemail}</td>
                                <td>{n?.showonmap ? "X" : ""}</td>
                                <td>{ui.getUser(n?.organizationowneruserid)?.name}</td>
                                <td>{ui.getUser(n?.organizationowneruserid)?.email}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const useOrganizations = () => {
    const [search, setSearch] = useState<string>("");
    const [data, setData] = useState<OrgType[]>([]);
    const [uids, setUids] = useState<string[]>([]);
    const users = useUsers();


    useEffect(() => {
        FetchHandler.getJson("/api/organizations").then((s: OrgType[]) => {
            setData(s);
            setUids(s?.map(m => m?.organizationowneruserid));
        })
    }, []);

    useEffect(() => {
        if (uids?.length > 0) {
            users.ensureUserInfo(uids);
        }
    }, [uids]);




    return {
        getUser: users?.getUser,
        data: data,
        search: search, setSearch: setSearch,
        doSearch: () => {
            setSearch("")
        },
    }
}

type OrgType = {
    id: string
    name: string
    description: string
    contact: string
    address: string
    postaladdress: string
    organizationnumber: string
    billingemail: string
    showonmap?: boolean
    organizationowneruserid: string
    sys_datemodified_utc_epoch: number
    sys_datecreated_utc_epoch: number
    weburl: string

    locationLatitude: number
    locationLongitude: number,
    typeOfBusiness: any
}

