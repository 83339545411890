import React, { useEffect, useRef, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { usePlant } from "../../hooks/usePlant";
import { SelectPlantsDialog } from "../Dialogs/SelectPlantsDialog";
import { EditDateKeyInput, EditSelectInput, EditStringInput } from "../FormComponents/EditInput";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { Dialog } from "../Shared/Dialog";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { TagSelector } from "../Shared/TagSelector";
import { ToolBar, ToolBarClean } from "../ToolBar/ToolBar";
import { ScreenHeader } from "../UI/Elements"
import { Overlay } from "../UI/Overlay"
import { AdItemStatisticsType, AdItemTypeType, AdStateType } from "./AdTypes";
import { useAdAdmin, useAdClients, useAdItemEditor } from "./useAdAdmin";
import { DateFormatter } from "../../Handlers/Helpers";
import { UploadImageToAdElement } from "../FormComponents/UploadImageElement";
import { ForumPostType } from "../Forum/Forum";
import { ForumPostCard } from "../Forum/ForumPostCard";


export const SponsoredPosts = () => {

    const ui = useSponsoredPosts();
    useEffect(() => {
        ui.load();
    }, [])

    return (
        <div className="screen-forum">
            <Overlay visible={false} />
            <ScreenHeader text="Sponsrade inlägg" icon={AdminIcons.ad} />
            <div className="forum-post-grid">
                {ui.data?.map(post => (
                    <div key={post?.id}>
                        <div>
                            <AdStats id={post?.id} />
                            <hr />
                            <ForumPostCard post={post} openImageOnClick={true} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}


const AdStats = ({ id }: { id: string }) => {

    const [statsData, setStatsData] = useState<AdItemStatisticsType>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const get = async () => {
        if (loading) { return; }
        setLoading(true);
        const data = await FetchHandler.getJson("/api/ads/" + id + "/statistics");
        setStatsData(data);
        setLoading(false);
    };

    return (
        <div>
            {!statsData && (
                <button className="btn btn-small" onClick={get}>Visa statistik</button>
            )}
            {!!statsData && (
                <div>

                    <span>
                        Klick: {statsData?.num_press},
                    </span>
                    <span>
                        Klick(användare): {statsData?.num_users_press}
                    </span>
                </div>
            )}
        </div>
    )
}


const useSponsoredPosts = () => {
    const uiClients = useAdClients();
    const api = useSponsoredPostsApi();

    const [data, setdata] = useState<ForumPostType[]>([])

    return {
        load: async () => {
            const r = await api.getSponsoredPosts();
            setdata(r);
        },
        data: data,
    }
}

const useSponsoredPostsApi = () => {

    return {
        getSponsoredPosts: async () => {
            const r: ForumPostType[] = await FetchHandler.getJson("/api/ads/sponsoredposts");
            return r;
        },
    }
}