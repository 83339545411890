import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcons } from "../Shared/AdminIcon";
import { ToolBar } from "../ToolBar/ToolBar";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { JwtImage, JwtPlantImage, JwtPlantImageWithPreview } from "../UI/ImageElements";
import { useElasticMenu } from "./useElasticMenu";
import { useElasticSearch } from "./useElasticSearch";

export const ElasticSearchPlants = () => {
    const ui = useElasticSearch();

    // useEffect(() => {
    //     ui.load();
    // }, []);


    const menu = useElasticMenu();
    return (
        <div className="screen-elastic">
            <ScreenHeader text="Appsök växt med Elastic Search" icon={AdminIcons.leaf} />
            <ScreenMenu items={menu?.items} />

            <LoadingBar loading={ui.loadingPlants} />
            <div>
                <input placeholder="Sök..." className="frm" type={"search"} value={ui.searchPlants} onChange={e => ui.setSearchPlants(e.target.value)} onKeyUp={e => {
                    if (e.key == "Enter") {
                        ui.onSearchPlants(ui.searchPlants, { filterOutSort: ui.elasticcheck_filterout_checked });
                    }
                }} />
                <span>
                    <input type="checkbox" name="elasticcheck_filterout" id="elasticcheck_filterout" checked={ui.elasticcheck_filterout_checked} onClick={() => ui.setElasticcheck_filterout_checked(!ui.elasticcheck_filterout_checked)} />
                    <label htmlFor="elasticcheck_filterout">Ta inte med växter med sort</label>
                </span>
            </div>

            <div>
                <div>
                    Träffar: {ui?.plants?.total ?? 0} - Svarstid: {ui.plants?.durationms} ms.
                </div>
                <div>

                    {(ui.plants?.suggestions?.length > 0) && (
                        <div className="suggestions-container">
                            <label>
                                Menade du?
                            </label>

                            <div className="suggestions">
                                {(ui.plants?.suggestions?.length > 0) && ui.plants?.suggestions?.map(s => (
                                    <div className="suggestion" key={s} onClick={() => {
                                        ui.onSearchPlants(s);
                                        ui.setSearchPlants(s);
                                    }} >
                                        {s}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Namn</th>
                            <th>Sort</th>
                            <th>Latin</th>

                            <th>Alt. namn</th>
                            <th>Alt nyckelord</th>
                            <th>Synonym</th>
                            <th>Synonym Latin</th>

                            <th>Typ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.plants?.hits?.map(hit => (
                            <tr key={hit?.Id}>
                                <td className="img">

                                    <JwtPlantImageWithPreview imageId={hit?.imageid} />
                                    {/* <JwtPlantImage plantImageId={hit?.imageid} sizeType="small" /> */}
                                </td>
                                <td>
                                    <Link to={"/plants/edit/" + hit.Id}>
                                        {hit?.Name}
                                    </Link>
                                </td>
                                <td>{hit?.specifictype}</td>
                                <td>{hit?.namelatin}</td>
                                <td>{hit?.alternativenames}</td>
                                <td>{hit?.alternativekeywords}</td>
                                <td>{hit?.alternativename_type}</td>
                                <td>{hit?.alternativename_latin}</td>
                                <td>{hit?.Type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
